import "../styles/Navbar.css"


import {useState, useEffect, React} from "react";
import { NavLink } from "react-router-dom";

export default function Navbar(){
  const [scrolling, setScrolling] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 45) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };
  return (
    <div className={scrolling ? "top-navigation scrolled" : "top-navigation"}>
        <div className={scrolling ? "navbar scrolled" : "navbar"}>
            <NavLink exact to= "/" activeClassName="active">Home</NavLink>
            <NavLink to="/roblox">Roblox</NavLink>
            <NavLink to="/discord">Discord</NavLink>
        </div>
    </div>
  );
}