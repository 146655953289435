
import "../styles/Tos.css"

export default function Yingsic() {
    return (
        <div className="container not-scrollable">
            <h1>----------------------------</h1>
            <h1>----------------------------</h1>
            <h1>TOS,P/P</h1>
            <h1>----------------------------</h1>
            <h2>1. 사용자 책임</h2>
            <p>사용자는 디스코드 이용 약관 및 커뮤니티 가이드를 준수해야 합니다.</p>
            <p>NSFW 기능은 성인 사용자만 사용할 수 있으며, 이에 대한 책임은 서버 관리자에게 있습니다.</p>

            <h2>2. 서비스 제공 및 변경</h2>
            <p>본 봇은 개발자의 재량에 따라 언제든지 업데이트되거나 기능이 변경될 수 있습니다.</p>

            <h2>3. 금지된 행위</h2>
            <ul>
                <li>봇을 해킹하거나 변조하는 행위</li>
                <li>자동화된 스크립트를 사용하여 봇을 오용하는 행위</li>
                <li>타인을 괴롭히거나 불법적인 콘텐츠를 공유하는 행위</li>
            </ul>

            <h2>4. 책임 제한</h2>
            <p>본 봇은 무료로 제공되며, 사용 중 발생하는 문제에 대해 책임을 지지 않습니다.</p>

            <h1>개인정보 보호 정책 (Privacy Policy)</h1>
            <p><strong>최종 업데이트:</strong> 2/27/2025</p>

            <h2>1. 수집하는 정보</h2>
            <ul>
                <li>디스코드 사용자 ID</li>
                <li>서버 설정 및 사용자 입력 데이터</li>
            </ul>

            <h2>2. 정보 사용 목적</h2>
            <p>봇 기능 제공 및 개선, 서버 관리 및 커뮤니티 유지, NSFW 기능을 위한 연령 제한 적용</p>

            <h2>3. 정보 보호 및 저장</h2>
            <p>수집된 데이터는 안전한 방식으로 저장되며, 사용자가 요청하면 삭제할 수 있습니다.</p>

            <h2>4. 사용자 권리</h2>
            <p>사용자는 자신의 데이터를 요청하고 삭제할 수 있습니다.</p>

            <h2>5. 정책 변경</h2>
            <p>본 개인정보 보호 정책은 예고 없이 변경될 수 있으며, 변경 사항은 공식 채널을 통해 공지됩니다.</p>
        </div>
    );
}
