import "./styles/Base.css"
import Navbar from "./default/navbar";
import Home from "./pages/home";
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import NotFound from "./pages/404";
import Success from "./pages/success";
import Project from "./pages/project";
import Yingsic from "./pages/yingsic";
export default function Main(){


  return (
    <div>
      <BrowserRouter>
        <Navbar/>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/bot-explain" element={<Yingsic/>}/>
          <Route path="/project/:id" element={<Project/>}/>
          <Route path="/showsuccess" element={<Success/>}/>
          <Route path="*" element={<NotFound/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}