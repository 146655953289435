import React, { useEffect } from 'react';
import "../styles/Base.css"
import "../styles/Home.css"
import Background from "../images/hero-background.png"
import YSB from "../images/ysb-image.png"

export default function Home(){
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('fade-in-visible');
                } else {
                    entry.target.classList.remove('fade-in-visible');
                }
            });
        }, { threshold: .35 });

        const sections = document.querySelectorAll('.hero-section');
        sections.forEach((section) => {
            observer.observe(section);
        });

        return () => {
            sections.forEach((section) => {
                observer.unobserve(section);
            });
        };
    }, []);
    /*style={{backgroundImage: `url(${Background})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}*/ 
    return (
        <div className="container">
            <section className="hero-section top"> 
                <h1>i do whatever <span className='highlight'>i want.</span></h1>
                <p>welcome to boyangsic.com</p>
                <div className='spacer'></div>
                <button 
                    className='hlbutton' 
                    onClick={() => window.scrollTo({ top: window.innerHeight * 0.9, behavior: "smooth" })}
                >Projects</button>
            </section>
            <section className="hero-section">
                <div className='product'>
                    <img src={YSB} alt='image25' className='product-image'></img>
                    <div className='product-explain'>
                        <h1 className='product-title'>양식봇</h1>  
                        <p className='product-desc'>
                            재미, nsfw, 관리등 기본적으로 디스코드 봇에 필요한 많은 것을 한번에 담아둔 봇!
                        </p>
                        <button 
                            className='hlbutton product-button' 
                            onClick={() => window.open("https://discord.com/oauth2/authorize?client_id=1307592123985559652", "_blank")}
                        >
                        Invite
                        </button>
                    </div>
                </div>
            </section>
            <section className="hero-section">
                <h1>nothing much here... :P</h1>
            </section>
        </div>
    );
}
